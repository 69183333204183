import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
  const videoStyle = {
    position: 'relative',
    maxWidth: '100%'
  }
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/generalSlides/jereo-2019.jpg'} alt={'plakat jereo 2019'} style={{ padding: '5px 5px 30px 5px', margin: '10vw auto 0', width: '100%' }} />

              <iframe style={videoStyle} src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fopolska.odnowa%2Fvideos%2F483596212565480%2F&show_text=0&width=476" width="476" height="476" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true" title="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fopolska.odnowa%2Fvideos%2F483596212565480%2F&show_text=0&width=476"></iframe>
              <br />
              <iframe style={videoStyle} src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fopolska.odnowa%2Fposts%2F2429954673724208&width=500" width="500" height="650" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" title="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fopolska.odnowa%2Fposts%2F2429954673724208&width=500"></iframe>
              <br />
              <iframe style={videoStyle} src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fopolska.odnowa%2Fvideos%2F2429027410683430%2F&show_text=0&width=560" width="560" height="315" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true" title="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fopolska.odnowa%2Fvideos%2F2429027410683430%2F&show_text=0&width=560"></iframe>
              <br />
              <img src={'/jereo-2019/1.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/2.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/3.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/4.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/5.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/6.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/7.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/8.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/9.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/10.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/11.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/12.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/13.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/14.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/15.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/16.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/17.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/18.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/19.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/20.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/21.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/22.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/23.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/24.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/25.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/26.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/27.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/28.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/29.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/30.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/31.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/32.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/33.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/34.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/35.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/36.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/37.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/38.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/39.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/40.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/41.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/42.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/43.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/44.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/45.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/46.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/47.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/48.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/49.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/50.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/51.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/52.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/53.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/54.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/55.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/56.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/57.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/58.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/59.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/60.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/61.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/62.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/63.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/jereo-2019/64.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />

          </div>
        </div>
        <div className={classes.container} style={{ padding: '50px' }}></div>
      </div>
    </Layout>
  );
}

